import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Option, Select, selectClasses } from "@mui/joy";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import kalima from "./assets/img/kalima.svg";
import mosque from "./assets/img/mosque2.svg";
import start from "./assets/img/start-icon.svg";
import voice from "./assets/img/voice-icon.svg";
import {
  AyatBox,
  backgroundBg,
  bodyL,
  bodyXs,
  contentWrapper,
  dateDouble,
  h2,
  logoWarapper,
  mosqueImg,
  stopRecordingBtn,
  timeStyle,
  welcomeCard,
} from "./VerseTranslationStyle";

import quran_eng from "./quran_eng.json";
import Fuse from "fuse.js";
import { normalizeArabicText } from "./utils/normalizeArabicText";
import { dataForWholeQuranSearchAbleFormat, surahNameArray } from "./static";
import FeedbackForm from "./FeedbackForm";
import muteIcon from "./assets/img/mute.png";
import unmuteIcon from "./assets/img/unmute.png";

let newId = "",
  list = [],
  surahFlag = false,
  surahName = "",
  lastAyahId = "",
  currentChunkStart = 0; // Start of current chunk in verses array
const chunkSize = 3; // Define the number of verses per chunk
let isMuted = false; // Global flag to track mute status

const VerseTranslation = () => {
  const [viewWidth] = useState(window.innerWidth);
  const [stopListeningFlag, setStopListeningFlag] = useState(false);
  const [isListening, setIsListening] = useState(false); // Track if recognition is active
  const [recognizedText, setRecognizedText] = useState("");
  const [translationRecognizedText, setTranslationRecognizedText] =
    useState("");
  const [translations, setTranslations] = useState([]); // Hold multiple translations
  const [language, setLanguage] = useState("english"); // Default language
  const [quranData] = useState(quran_eng);
  const [surahData] = useState(surahNameArray);
  const [fuse, setFuse] = useState(null);
  const recognitionRef = useRef(null);
  const [flag, setFlag] = useState(false); // Switch UI based on recording state
  const debounceTimerRef = useRef(null); // Ref for debounce timer
  const accumulatedTranscriptRef = useRef(""); // Ref to accumulate transcript
  const [processedVerses, setProcessedVerses] = useState(new Set()); // Track processed verses
  const [versesList, setVersesList] = useState([]);

  let translationsArray = useRef(new Set());

  //   const captureAudioStream = async () => {
  //     try {
  //       const audioStream = await navigator.mediaDevices.getUserMedia({
  //         audio: {
  //           noiseSuppression: true,
  //           echoCancellation: true,
  //           autoGainControl: true,
  //         },
  //       });
  //       audioStreamRef.current = audioStream; // Store the stream in the ref
  //     } catch (err) {
  //       console.error("Error accessing microphone:", err);
  //     }
  //   };

  //   captureAudioStream();

  //   return () => {
  //     if (audioStreamRef.current) {
  //       audioStreamRef.current.getTracks().forEach((track) => track.stop());
  //     }
  //   };
  // }, []);

  // Surah Name array loaded once
  useEffect(() => {
    if (surahData?.length === 0) return;
    const verses = [];
    surahData?.forEach((verse) => {
      const normalizedText = normalizeArabicText(verse.text);
      verses.push({
        name: verse?.name,
        id: verse?.surahId,
        text: verse?.text, // Original Arabic verse
        normalizedText: normalizedText, // Normalized Arabic verse for search
        translation: verse?.translation, // Translation based on selected language
      });
    });
    setVersesList((prevState) => {
      return verses;
    });

    // Initialize Fuse with appropriate threshold
    const fuseInstance = fuseInstanceFn(verses, 0.1);

    setFuse(fuseInstance);
  }, []);

  const fuseInstanceFn = (quranList, thresholdValue) => {
    const fuseInstance = new Fuse(quranList, {
      keys: ["normalizedText"],
      threshold: thresholdValue,
      includeScore: true,
      includeMatches: true,
    });
    return fuseInstance;
  };

  // Initialize Speech Recognition
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognitionRef.current = recognition;
      recognition.lang = "ar-SA"; // Arabic language
      recognition.continuous = true; // Continuous listening
      recognition.interimResults = true; // Only final results

      recognition.onstart = () => {
        setIsListening(true);
        console.log("Speech recognition started.");
      };

      // Function to check for matches in the transcript

      recognition.onresult = (event) => {
        let interimTranscript = "";
        // Step 1: Populate interimTranscript with the speech result
        interimTranscript += event.results[0][0].transcript + " ";
        // // Step 2: Normalize interimTranscript for comparison
        const normalizedInterimTranscript = normalizeArabicText(
          interimTranscript.trim()
        );

        // Step 3: Check if the transcript starts with "Bismillah"
        const isBismillah = normalizedInterimTranscript.startsWith(
          normalizeArabicText("بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ")
        );

        if (isBismillah) {
          interimTranscript = "بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ";
          //console.log("if case for interim - detected Bismillah");

          // Stop recognition
          recognition.stop();

          // Wait for recognition to stop before starting again
          recognition.onend = () => {
            // Restart recognition after a brief pause
            recognition.start();
          };
        } else {
          interimTranscript = "";
          for (let i = event.resultIndex; i < event.results.length; i++) {
            interimTranscript += event.results[i][0].transcript + " ";
          }
        }

        const finalNormalizedTranscript = normalizeArabicText(
          interimTranscript.trim()
        );

        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
          checkForMatches(finalNormalizedTranscript);
          accumulatedTranscriptRef.current = ""; // Reset after processing
        }, 300); // 1-second debounce

        if (Boolean(interimTranscript.trim())) {
          setRecognizedText(interimTranscript.trim()); // Display what the user is saying
        }
      };

      recognition.onerror = (event) => {
        console.log("Speech Recognition Error:", event.error);
        setIsListening(false);

        // Optionally, restart recognition on certain errors
        if (
          event.error === "not-allowed" ||
          event.error === "service-not-allowed"
        ) {
          // Permission denied, do not restart
        } else {
          restartRecognition();
        }
      };

      recognition.onend = () => {
        console.log("Speech recognition ended.");
        // Restart recognition to ensure continuous listening
        recognition.start();
      };
    } else {
      console.error("Speech Recognition not supported in this browser.");
    }

    // Cleanup on unmount
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuse]);

  // Function to restart recognition
  const restartRecognition = () => {
    if (recognitionRef.current && isListening) {
      try {
        recognitionRef.current.start();
      } catch (error) {
        console.error("Error restarting Speech Recognition:", error);
      }
    }
  };

  // Function to load the next chunk of verses safely

  const loadNextChunk = (surahLength, surahVerses, currentVerseIndex) => {
    try {
      // Calculate the end of the current chunk
      const chunkEnd = Math.min(currentVerseIndex + chunkSize, surahLength);

      // console.log("chunkEnd", chunkEnd, "currentChunkStart", currentChunkStart, "surahLength", surahLength, "chunkSize", chunkSize, "currentVerseIndex",currentVerseIndex);

      // Ensure `surahVerses` is an array and slice the next chunk
      const versesChunk = Array.isArray(surahVerses)
        ? surahVerses.slice(currentVerseIndex, chunkEnd).map((verse) => ({
            id: verse.verseId,
            text: verse.text,
            normalizedText: normalizeArabicText(verse.text),
            translation: verse.translation,
          }))
        : [];

      if (versesChunk.length === 0) {
        //console.log( `No verses loaded. Current start: ${currentChunkStart}, End: ${chunkEnd}`);
      }

      // // Clear the previous chunk and update with the new chunk
      list = [...versesChunk]; // Replace `list` with the new chunk
      setVersesList([...versesChunk]); // Replace `versesList` state with the new chunk

      // Update `currentChunkStart` for the next call
      currentChunkStart = chunkEnd;
    } catch (error) {
      console.error("Error loading next chunk:", error);
    }
  };

  function searchInWholeQuran(transcript) {
    const NormalizedTexting = normalizeArabicText(transcript);
    console.log("NormalizedTexting value:!!!", NormalizedTexting);
    const fuseInstance = fuseInstanceFn(dataForWholeQuranSearchAbleFormat, 0.2);

    const foundedSurahId = fuseInstance.search(NormalizedTexting);
    //console.log("foundedSurahId:", foundedSurahId);

    if (foundedSurahId.length > 0) {
      //console.log("Found Surah ID:", foundedSurahId[0]?.item?.surahId);
      surahName = foundedSurahId[0]?.item?.name;
      newId = foundedSurahId[0]?.item?.surahId;
      let verseId = foundedSurahId[0]?.item?.verseId;

      const surahData = quranData[newId - 1];
      if (!surahData || !surahData.verses) {
        console.error("Error: Invalid Surah data for ID:", newId);
        return;
      }

      const surahVerses = surahData.verses || [];
      const surahLength = surahVerses.length;
      loadNextChunk(surahLength, surahVerses, verseId);
      surahFlag = true;
    } else {
      //console.log("No Surah ID found in the transcript");
    }
  }

  const checkForMatches = async (transcript) => {
    // Reset translationsArray when Surah ends

    if (transcript && newId < 1 && !surahFlag) {
      //console.log("Checking transcript value:", transcript);

      bismillahDetection(transcript);

      const NormalizedTexting = normalizeArabicText(transcript);
      //console.log("NormalizedTexting value:", NormalizedTexting);
      const fuseInstance = fuseInstanceFn(versesList, 0.2);

      const words = transcript.split(" ");

      if (words?.length < 2) {
        return;
      } else {
        console.log("inside surah first name search", transcript);
        const foundedSurahId = fuseInstance.search(NormalizedTexting);
        //console.log("foundedSurahId:", foundedSurahId);

        if (foundedSurahId.length === 1) {
          //console.log("Found Surah ID:", foundedSurahId[0]?.item?.id);
          surahName = foundedSurahId[0]?.item?.name;
          newId = foundedSurahId[0]?.item?.id;

          const surahData = quranData[newId - 1];
          if (!surahData || !surahData.verses) {
            console.error("Error: Invalid Surah data for ID:", newId);
            return;
          }

          const surahVerses = surahData.verses || [];
          const surahLength = surahVerses.length;
          loadNextChunk(surahLength, surahVerses, 0);
          surahFlag = true;
        } else {
          console.log("inside full quran search", transcript);
          searchInWholeQuran(transcript);
        }
      }
    } else if (newId && surahFlag) {
      processRecognition(transcript);
    }
  };

  const bismillahDetection = (transcript) => {
    const bismillahTranscript = normalizeArabicText(transcript);

    const isBismillah = bismillahTranscript.startsWith(
      normalizeArabicText("بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ")
    );

    if (isBismillah) {
      //console.log("Detected Bismillah");
      const bismillahTranslation =
        "In the name of Allah, the Entirely Merciful, the Especially Merciful";
      if (!translationsArray.current.has(bismillahTranslation)) {
        //console.log("in the Bismillah translation check");

        translationsArray.current.add(bismillahTranslation);
        lastAyahId = 0;
        setTranslationRecognizedText(bismillahTranscript);
        setTranslations([bismillahTranslation]);
        speakTranslation(bismillahTranslation);
      }
      transcript = transcript
        .replace("بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ", "")
        .trim();

      // Continue to check for Surah matches
      if (!transcript) {
        //console.log("No other text after Bismillah. Resetting transcript.");
        // setTranslationRecognizedText(""); // Clear displayed translation
        recognitionRef.current.abort();
        return;
      }
    }
  };

  const processRecognition = (transcript) => {
    console.log("List Value", list);

    const fuseInstance = new Fuse(list, {
      keys: ["normalizedText"],
      threshold: 0.2,
      includeScore: true,
    });

    const NormalizedTexting = normalizeArabicText(transcript);
    const result = findMultipleMatches(NormalizedTexting, fuseInstance);

    //console.log("normalized text value in surah id check", NormalizedTexting);
    console.log("resullt checking", result);
    console.log("results length", result.length);
    if (result.length === 0) {
      console.log("going in if", transcript);
      if (transcript?.length > 10) {
        searchInWholeQuran(transcript);
      }
    } else {
      console.log("going in else case");
      result?.forEach((el) => {
        //console.log("Match in loop:", el);
        // Create a unique key by combining the verseId and its position in the surah

        if (!processedVerses?.has(el?.id)) {
          setProcessedVerses((prevProcessed) =>
            new Set(prevProcessed).add(el?.id)
          );
          if (!translationsArray?.current?.has(el?.translation)) {
            setTranslationRecognizedText(normalizeArabicText(el?.text));
            setTranslations([el?.translation]);
            translationsArray.current.add(el?.translation);
            speakTranslation(el?.translation);
          }
          lastAyahId = el?.id;
        }
      });

      console.log(list.length, lastAyahId, `lastAyahId`, newId, "newId");

      if (list[list.length - 1]?.id == lastAyahId) {
        if (currentChunkStart < quranData[newId - 1]?.verses.length) {
          loadNextChunk(
            quranData[newId - 1]?.verses.length,
            quranData[newId - 1]?.verses,
            lastAyahId
          );
        } else {
          resetter(transcript);
        }
      }
    }
  };

  const resetter = (transcript) => {
    newId = 0;
    currentChunkStart = 0;
    surahFlag = false;
    surahName = "";
    list = [];
    transcript = ""; // Reset transcript
    recognitionRef.current.abort(); // Stop the recognition
    // Clear the translationsArray only when Surah ends
    translationsArray.current.clear();
  };

  const findMultipleMatches = (transcript, fuseInstance) => {
    const words = transcript.split(" ").filter((word) => word.trim() !== "");
    const matches = [];
    let i = 0;

    while (i < words.length) {
      let matchFound = false;

      // Try to match from longest to shortest phrases starting at index i
      for (let j = words.length; j > i; j--) {
        const phrase = words.slice(i, j).join(" ");
        const normalizedPhrase = normalizeArabicText(phrase);
        const results = fuseInstance.search(normalizedPhrase);

        // Check for a match with a low enough score (high confidence)
        if (results.length > 0 && results[0].score <= 0.3) {
          const matchedVerse = results[0].item;

          // Avoid duplicates by checking if the verse ID is already added
          if (!matches.some((match) => match.id === matchedVerse.id)) {
            matches.push(matchedVerse);
          }

          i = j; // Move past the matched words in the phrase
          matchFound = true;
          break;
        }
      }

      if (!matchFound) {
        // Move to the next word if no match found for this starting word
        i += 1;
      }
    }

    return matches;
  };

  const startListening = () => {
    if (recognitionRef.current && !isListening) {
      try {
        recognitionRef.current.start();
        setFlag(true); // Switch to live UI
      } catch (error) {
        console.error("Error starting Speech Recognition:", error);
      }
    }
  };

  // Function to stop listening and reset
  const stopListening = () => {
    setIsListening(false);
    setTranslations([]);
    setRecognizedText("");
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setFlag(false);
    }
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    window.location.reload();
  };

  // Toggle mute function
  const handleMute = () => {
    isMuted = !isMuted; // Toggle the mute state
    const muteButton = document.getElementById("muteButton"); // Get the button element
    if (muteButton) {
      muteButton.textContent = isMuted ? "Unmute" : "Mute"; // Update button text
    }

    // Update the image/icon in the <Box>
    const muteIconElement = document.getElementById("muteIcon");
    if (muteIconElement) {
      muteIconElement.src = isMuted ? muteIcon : unmuteIcon; // Change the image based on state
      muteIconElement.alt = isMuted ? "Mute Icon" : "Unmute Icon"; // Update alt text
    }
  };

  // Function to speak the translation only
  const speakTranslation = (matchedVerse) => {
    return new Promise((resolve, reject) => {
      const synth = window.speechSynthesis;
      if (!synth) {
        console.error("Speech synthesis is not supported in this browser.");
        resolve();
        return;
      }
      const loadVoicesAndSpeak = () => {
        if (isMuted) {
          //console.log("Speech is muted. Skipping translation.");
          resolve(); // Resolve the promise without speaking
          return;
        }
        const voices = synth.getVoices();
        const utterance = new SpeechSynthesisUtterance(matchedVerse);

        if (language === "urdu") {
          const urduVoice =
            synth.getVoices().find((voice) => voice.lang === "ur-PK") ||
            synth
              .getVoices()
              .find((voice) => voice.name.toLowerCase().includes("urdu"));
          if (urduVoice) {
            utterance.voice = urduVoice;
            utterance.lang = urduVoice.lang;
          } else {
            console.error("Urdu voice not available in Speech Synthesis.");
          }
        } else if (language === "english") {
          // const englishVoice = synth
          //   .getVoices()
          //   .find((voice) => voice.lang.startsWith("en"));
          const englishVoice = voices.find((voice) =>
            voice.lang.startsWith("en")
          );
          if (englishVoice) {
            utterance.voice = englishVoice.volume;
            utterance.lang = englishVoice.lang;
          } else {
            console.error("English voice not available in Speech Synthesis.");
          }
        }

        utterance.onend = () => {
          resolve();
        };

        synth.speak(utterance);
      };
      // If voices are already loaded, speak immediately
      if (synth.getVoices().length > 0) {
        loadVoicesAndSpeak();
      } else {
        // Wait for voices to load
        synth.onvoiceschanged = () => {
          loadVoicesAndSpeak();
          synth.onvoiceschanged = null; // Remove listener after loading voices
        };
      }
    });
  };

  // Function to handle language selection
  const handleLanguageChange = (event, value) => {
    setLanguage(value);
    setTranslations([]); // Clear previous translations
    setRecognizedText(""); // Clear previous recognized text
    accumulatedTranscriptRef.current = ""; // Reset accumulated transcript
    if (isListening && recognitionRef.current) {
      // Restart recognition with new language
      recognitionRef.current.stop();
      setIsListening(false);
      setTimeout(() => {
        recognitionRef.current.lang = value === "urdu" ? "ur-PK" : "ar-SA"; // Adjust 'ar-SA' to 'en-US' if speech recognition is also used for English
        recognitionRef.current.start();
        setIsListening(true);
      }, 500);
    }
  };

  const date = new Date();
  const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
  const day = date.toLocaleDateString("en-US", { day: "numeric" });
  const month = date.toLocaleDateString("en-US", { month: "long" });
  const year = date.toLocaleDateString("en-US", { year: "numeric" });

  // Manually construct the desired format
  const formattedDate = `${weekday}, ${day} ${month} ${year}`;

  return (
    <Box sx={backgroundBg}>
      <Box sx={contentWrapper}>
        <Box sx={logoWarapper}>
          <img src={kalima} alt="Logo" />
        </Box>
        <Box sx={welcomeCard}>
          <Box className="time">
            <Box sx={{ marginBottom: "0px", ...bodyXs }} className="today">
              Today
            </Box>
            <Box sx={timeStyle}>
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Box sx={h2}>Welcome to the Quran Listening Application</Box>
            <Box sx={{ marginTop: "2px", ...bodyXs }}>
              Immerse yourself in the recitation of the Holy Quran with ease.
              Listen, reflect, and understand the divine message anytime,
              anywhere
            </Box>
            <Box sx={dateDouble}>
              <Box sx={{ marginTop: "10px", ...bodyXs }}>{formattedDate}</Box>
            </Box>
          </Box>
          <Box className="mosque-img" sx={mosqueImg}>
            <img
              src={mosque}
              alt="Mosque"
              style={{ borderBottomRightRadius: "20px" }}
            />
          </Box>
        </Box>
        {flag ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ marginBottom: "10px", marginTop: "50px", ...bodyL }}
                  >
                    Live
                  </Box>
                  <Box
                    onClick={handleMute}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      id="muteIcon"
                      src={isMuted ? muteIcon : unmuteIcon}
                      alt={isMuted ? "Unmute Icon" : "Mute Icon"}
                      style={{ filter: "invert(1)" }}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Box>

                <Box sx={{ ...AyatBox }}>
                  <Box sx={{ height: "170px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "18px",
                      }}
                    >
                      {surahName}
                    </Box>
                    <Box id="live" sx={{ direction: "rtl" }}>
                      {translationRecognizedText?.length > 0 ? (
                        <span>{`[${newId}:${lastAyahId}] - ${translationRecognizedText}`}</span>
                      ) : (
                        <p style={{ color: "#fff" }}>
                          No Quran recitation detected
                        </p>
                      )}
                    </Box>
                    <Box id="translation">
                      {translations.length > 0 ? (
                        translations.map((trans, index) => (
                          <p style={{ color: "#fff" }} key={index}>
                            {`[${newId}:${lastAyahId}] - ${trans}`}
                          </p>
                        ))
                      ) : (
                        <p style={{ color: "#fff" }}>
                          No translation available.
                        </p>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              <Box sx={{ marginTop: "20px" }}>
                <Button
                  sx={stopRecordingBtn("#8a1225")}
                  onClick={() => {
                    setStopListeningFlag(true);
                    stopListening();
                  }}
                >
                  Stop Recording
                </Button>
              </Box>
              <Box
                sx={{
                  marginTop: "30px",
                  fontSize: "32px",
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                Listening...
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <img src={voice} alt="Voice" />
              </Box>

              <FeedbackForm />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "30px",
            }}
          >
            <Select
              placeholder="Select Language"
              value={language}
              onChange={handleLanguageChange}
              indicator={<KeyboardArrowDown />}
              sx={{
                width: 240,
                backgroundColor: "#2C5741",
                border: "1px solid #fff",
                color: "#fff",
                "&:hover ": {
                  backgroundColor: "#2C5741",
                },
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              <Option value="english">English</Option>
              {/* <Option value="urdu">Urdu</Option> */}
            </Select>
            <Box
              sx={{ marginTop: "20px", cursor: "pointer" }}
              onClick={startListening}
            >
              <img src={start} alt="Start" />
            </Box>
            <Box
              sx={{
                marginTop: "30px",
                fontSize: "32px",
                color: "#fff",
                fontWeight: "500",
              }}
            >
              Start Listening
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VerseTranslation;
