import "./App.css";
import VerseTranslation from "./VerseTranslation";

function App() {
  return (
    <div className="app">
      <VerseTranslation />
    </div>
  );
}

export default App;
